<!--
File: Roads.vue
Description: show list of roads entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            :initial_value="selectedRoadClass" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="text" class="mb-3" clearable :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addNewRoad"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-table :value="queriedData" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.road_class')" md-sort-by="road_class">
            {{ item.road_class }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.road_number')" md-sort-by="road_number">
            {{ item.road_number }}
          </md-table-cell>
          <md-table-cell style="min-width: 50%;" :md-label="$t('stdCols.name')" md-sort-by="road_description">
            {{ item.road_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length')" md-sort-by="length_m">
            {{ item.length_m | numFormat(0) }}
          </md-table-cell>
          <md-table-cell style="text-align: center;" :md-label="$t('road_network.section_count')"
            md-sort-by="section_count">
            <!--Uncomment when find a way of giving region_id -->
            <!--a v-if="item.section_count > 0" href='#' @click.stop.prevent='showSections(item.road_id)'-->
              {{ item.section_count }}<!--/a-->
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div class="md-layout-item btn-row md-small-size-100">
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editRoad(item.road_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for=" item in footerTable " :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for=" item in pagination.perPageOptions " :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
import { Pagination } from '@/components'
import Swal from 'sweetalert2'
import Fuse from 'fuse.js'
//import NetworkDropdown from '../Dropdowns/NetworkDropdown.vue'
import { mapState, mapGetters } from 'vuex'
import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
import { customSortMixin } from '@/mixins/customSortMixin'

export default {
  mixins: [customSortMixin],
  name: 'roads-list',
  data() {
    return {
      selected_item: {},
      selected_roadnetwork: null,
      selectedRoadClass: null,  //'',

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 20, 30, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['road_description', 'road_class', 'road_number', 'length_m'],
      searchedData: [],
      currentSort: 'road_number',
      currentSortOrder: 'asc',
      fuseSearch: null,
      footerTable: []
    }
  },
  components: {
    Pagination,
    //NetworkDropdown,
    RoadClassesDropdown
  },

  mounted() {
    this.$store.dispatch('LOAD_HISTORY').then((history) => {
      if (history.for === 'roads' && history.use) {
        this.selectedRoadClass = history.data.selectedRoadClass
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        this.searchQuery = history.data.searchQuery
        this.$nextTick(() => {
          this.pagination.currentPage = history.data.currentPage
        })
        this.$store.dispatch('CLEAR_HISTORY')
      } else {
        this.selectedRoadClass = null
      }
      this.reloadData()
    })
  },

  methods: {
    reloadData() {
      const searchQuery = this.searchQuery
      this.searchQuery = ''
      this.$store.dispatch('LOAD_ROADS_LIST', this.selectedRoadClass).then(() => {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.1 })
        if (searchQuery) this.searchQuery = searchQuery
      })
    },
    save_history() {
      const hist = {
        selectedRoadClass: this.selectedRoadClass,
        searchQuery: this.searchQuery,
        currentSort: this.currentSort,
        currentSortOrder: this.currentSortOrder,
        perPage: this.pagination.perPage,
        currentPage: this.pagination.currentPage
      }
      //localStorage.setItem('history', btoa(unescape(encodeURIComponent(JSON.stringify(hist)))))
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'roads' })
    },
    showSections(road_id) {
      const hist = {
        selected_road: road_id,
        perPage: 20,
        currentPage: 1
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
      this.$store.dispatch('SET_USE_HISTORY')
      this.$router.push('/inventory_data/sections')
    },
    editRoad(id) {
      this.save_history()
      this.$router.push(`/inventory_data/roads/upd/${id}`)
    },
    addNewRoad() {
      this.save_history()
      this.$router.push(`/inventory_data/roads/add`)
    },
    handleDelete(item) {
      const alert = {
        title: this.$t('modal.are_you_sure'),
        html: this.$t('modal.you_want_delete', { name: item.road_description }) + '<br><br>' +
          this.$t('modal.the_operation_cannot_be_canceled'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('modal.yes_delete'),
        cancelButtonText: this.$t('buttons.cancel'),
      }
      this.save_history()
      Swal.fire(alert).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_ROAD', item.road_id).then(
            () => {
              this.reloadData()
              Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.road_description }), 'success')
            },
            (err) => {
              Swal.fire(this.$t('messages.error'), err, 'error')
            }
          )
        }
      })
    },
  },

  computed: {
    ...mapState({
      tableData: (state) => state.Roads.list,
    }),
    ...mapGetters(['amIAdmin']),
    queriedData() {
      const result = !this.searchQuery ? this.tableData : this.searchedData
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
  },
  watch: {
    searchQuery(value) {
      if (this.tableData.length == 0) {
        this.searchedData = []
        return
      }
      this.searchedData = (value === '' || !this.fuseSearch) ? this.tableData : this.fuseSearch.search(value)
    }
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
}
</style>